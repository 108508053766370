<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
  >
    <path
      d="M24,6.9c0,0.4-0.2,0.9-0.5,1.2L13.2,18.4c-0.7,0.7-1.8,0.7-2.4,0L0.5,8.1c-0.7-0.7-0.7-1.8,0-2.4C1.2,5,2.3,5,2.9,5.7l9.1,9l9.1-9.1c0.7-0.7,1.8-0.7,2.4,0C23.8,6,24,6.4,24,6.9z"
    />
  </svg>
</template>
